import { environment as defaultEnv } from './environment.default';
import { Environment } from './Type';

export const environment: Environment = {
  ...defaultEnv,
  debug: false,
  production: true,

  lang: 'sk',
  currency: '€',

  api: {
    protocol: 'https',
    host: 'api.edotazniky.sk/api/v1',
  },

  recaptchaKey: '6LdXGhknAAAAAAabbvfBvAIF3ASj1ICz9GAMpjFd',

  defaultPagination: {
    perPage: 50,
  },
};
